import { isString } from '@borg/utils';

export default defineNuxtRouteMiddleware((to) => {
  if (process.server) {
    return;
  }
  const userInfo = useUserInfo();
  const jobId = to.params.jobId;
  const source = to.query.source?.toString() ?? 'no_slug';

  if (jobId && isString(jobId)) {
    userInfo.setSeen(jobId);
    jobsService.trackPageVisit(jobId, source);
  }
});
